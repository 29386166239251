import Vue from 'vue'
import App from './App.vue'
import VueGeolocation from 'vue-browser-geolocation'
import Notifications from 'vue-notification'
import router from './router'

Vue.config.productionTip = false

Vue.use(VueGeolocation);
Vue.use(router);
Vue.use(Notifications);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
