<template>
  <div class="navigator">
    <vue-element-loading :active="is_loading" style="z-index: 999999999;" :is-full-screen="true" spinner="spinner" color="#4CE5B1" />

    <div id="map"></div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-routing-machine';
import VueElementLoading from "vue-element-loading";

export default {
  name: 'Navigator',

  components: {
    VueElementLoading
  },

  data() {
    return {
      is_loading: true,
      map: '',
      getted_location: false,
      is_taxi: '',
      is_a: '',
      is_b: '',
      iconTaxi: '',
      iconA: '',
      iconB: '',
      latA: '',
      lngA: '',
      latB: '',
      lngB: '',
    }
  },

  async mounted() {
    const urlParams = new URL(document.URL).searchParams;

    this.latA = parseFloat(urlParams.getAll("points[0][lat]")[0]);
    this.lngA = parseFloat(urlParams.getAll("points[0][lng]")[0]);
    this.latB = parseFloat(urlParams.getAll("points[1][lat]")[0]);
    this.lngB = parseFloat(urlParams.getAll("points[1][lng]")[0]);

    await this.getLocation();
  },

  methods: {

    async getLocation() {
      await this.$getLocation({})
          .then(async (coordinates) => {
            this.is_loading = false;

            if (!this.getted_location) {
              await this.setup();
            }

            await this.setMapMarker(coordinates.lat, coordinates.lng);

            setTimeout(this.getLocation, 3000);
          })
          .catch(err => {
            console.log(err);
            this.is_loading = true;
            setTimeout(this.getLocation, 3000);
          })
    },

    async setup() {
      this.getted_location = true;

      let cord;
      await this.$getLocation({})
          .then(coordinates => {
            cord = coordinates;
          });
      var map = await L.map('map').setView([ cord.lat, cord.lng ], 20);

      this.iconTaxi = L.icon({
        iconUrl: 'images/marker-a.png',
        iconSize: [40, 40],
        iconAnchor: [20, 20]
      });

      this.iconA = L.icon({
        iconUrl: 'images/raise-hand.png',
        iconSize: [40, 40],
        iconAnchor: [20, 20]
      });

      this.iconB = L.icon({
        iconUrl: 'images/marker-b.png',
        iconSize: [40, 40],
        iconAnchor: [20, 20]
      });

      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      this.map = map;

      await this.setMapMarkerA(this.latA,  this.lngA);
    },

    async setMapMarker(lat, lng) {
      if (this.is_taxi) this.is_taxi.remove();
      if (this.is_taxi) this.is_taxi = '';
      this.is_taxi = await L.marker([lat, lng], {icon: this.iconTaxi}).addTo(this.map);

      this.checkRouting();
    },

    async setMapMarkerB(lat, lng) {
      this.is_b = await L.marker([lat, lng], {icon: this.iconB}).addTo(this.map);

      this.controlAB = await L.Routing.control({
        waypoints: [
          [this.is_a._latlng.lat, this.is_a._latlng.lng],
          [this.is_b._latlng.lat, this.is_b._latlng.lng]
        ],
        lineOptions: {
          styles: [{color: '#7439fb', opacity: 1, weight: 5}]
        },
        show: false,
        waypointMode: 'snap',
        createMarker: function() {}
      });

      this.controlAB.addTo(this.map);
    },

    async setMapMarkerA(lat, lng) {
      this.is_a = await L.marker([lat, lng], {icon: this.iconA}).addTo(this.map);

      await this.setMapMarkerB(this.latB,  this.lngB);
    },

    checkRouting() {

      if (this.is_taxi && this.is_a) {
        if (this.control) {
          this.control.remove();
          this.control = '';
        }

        this.control = L.Routing.control({
          waypoints: [
            [this.is_taxi._latlng.lat, this.is_taxi._latlng.lng],
            [this.is_a._latlng.lat, this.is_a._latlng.lng]
          ],
          lineOptions: {
            styles: [{color: '#fb3939', opacity: 1, weight: 5}]
          },
          show: false,
          waypointMode: 'snap',
          createMarker: function() {}
        });

        this.control.addTo(this.map);
      }
    },

  }
}
</script>

<style>
@import '/css/font/sf-ui-display/style.css';
@import '/css/leaflet.css';

body {
  padding: 0;
  margin: 0;
  height: 100vh;
}
#app {
  width: 100%;
  height: 100%;
}
.navigator {
  width: 100%;
  height: 100%;
}
#map {
  width: 100%;
  height: 100%;
}
.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

</style>
