<template>
  <div class="steps">
    <vue-element-loading :active="isLoading" style="z-index: 999999999;" :is-full-screen="true" spinner="spinner" color="#4CE5B1" />
    <step-1
        :is_address_a="is_address_a"
        :is_address_b="is_address_b"
        :is_a="is_a"
        :is_b="is_b"
        :town="town"
        :city_has="city_has"
        @newA="(e) => $emit('newA', e)"
        @newB="(e) => $emit('newB', e)"
        @setIs="(e) => $emit('setIs', e)"
        @clearPoint="(e) => $emit('clearPoint', e)"
        :close="step_close_1"
        @setCurrentLocation="(e) => $emit('setCurrentLocation', e)"
        @createOrder="e => createOrder(e)"
    />
    <step-2
        @cancelOrder="cancelOrder"
        :close="step_close_2"
        :start_point="start_point"
        :end_point="end_point"
        @setCurrentLocation="(e) => $emit('setCurrentLocation', e)"
    />
    <step-3
        :partner="partner"
        :price="price"
        :is_address_a="start_point"
        :is_address_b="end_point"
        @newOrder="newOrder"
        :close="step_close_3"
        @setCurrentLocation="(e) => $emit('setCurrentLocation', e)"
    />
  </div>
</template>

<script>
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import ApiService from "@/services/api";
import VueElementLoading from "vue-element-loading";

export default {
  name: 'Steps',

  components: {
    VueElementLoading,
    Step1,
    Step2,
    Step3
  },

  props: [ 'city_has', 'town', 'close', 'is_address_a', 'is_address_b', 'is_a', 'is_b' ],

  data() {
    return {
      isLoading: false,
      step: 1,
      order_id: null,
      partner: '',
      price: '',
      start_point: '',
      end_point: '',
      start_point_A: '',
      end_point_B: '',
      onCancel: false
    }
  },

  computed: {
    step_close_1: function () {

      if (this.close) return true;
      if (this.step === 1) return false;

      return true;
    },

    step_close_2: function () {

      if (this.close) return true;
      if (this.step === 2) return false;

      return true;
    },

    step_close_3: function () {

      if (this.close) return true;
      if (this.step === 3) return false;

      return true;
    }
  },

  mounted() {
    if (localStorage.getItem('partner')) {
      this.step = 3;
      this.$emit('setStep', 3);
      this.$emit('changedMap', false);
      this.partner = JSON.parse(localStorage.getItem('partner'));
      this.price = JSON.parse(localStorage.getItem('price'));
      this.start_point = localStorage.getItem('start_point');
      this.end_point = localStorage.getItem('end_point');
      this.start_point_A = JSON.parse(localStorage.getItem('start_point_A'));
      this.end_point_B = JSON.parse(localStorage.getItem('end_point_B'));
    }
    else if (localStorage.getItem('order_id')) {
      this.step = 2;
      this.$emit('setStep', 2);
      this.$emit('changedMap', false);
      this.order_id = localStorage.getItem('order_id');
      this.checkOrder();
    }
  },

  methods: {

    newOrder() {
      this.step = 1;
      this.$emit('setStep', 1);
      this.$emit('changedMap', true);
      this.order_id = '';
      this.partner = '';
      this.price = '';
      this.start_point = '';
      this.end_point = '';
      this.start_point_A = '';
      this.end_point_B = '';
      document.getElementsByClassName('point-A-value')[0].focus();
      this.is = 'A';
      document.getElementsByClassName('point-B-value')[0].blur();
      this.$emit('newOrder');
    },

    createOrder(params) {
      this.isLoading = true;

      ApiService.createOrder(params)
          .then(res => {
            this.order_id = res.data.data.id;
            localStorage.setItem('order_id', this.order_id);
            this.step = 2;
            this.$emit('setStep', 2);
            this.$emit('changedMap', false);
            this.isLoading = false;
            this.checkOrder();
            this.$notify({
              group: 'main',
              type: 'success',
              text: res.data.message
            });
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
            this.$notify({
              group: 'main',
              type: 'error',
              text: JSON.stringify(error)
            });
          });
    },

    checkOrder() {
      let params = '?id=' + this.order_id;
      if (this.onCancel) return;

      ApiService.checkOrder(params)
          .then(res => {
            if (this.onCancel) return;
            if (res.data.data.status === 'in_process') {
              setTimeout(this.checkOrder, 1000);
              this.start_point = res.data.data.start_point;
              this.end_point = res.data.data.end_point;
            } else if (res.data.data.status === 'accepted') {
              this.partner = res.data.data.partner;
              this.price = res.data.data.price;
              this.start_point = res.data.data.start_point;
              this.end_point = res.data.data.end_point;
              this.start_point_A = res.data.data.points[0];
              this.end_point_B = res.data.data.points[1];
              this.$emit('setNewAB', this.start_point_A, this.end_point_B);
              localStorage.setItem('partner', JSON.stringify(this.partner));
              localStorage.setItem('price', JSON.stringify(this.price));
              localStorage.setItem('start_point', this.start_point);
              localStorage.setItem('end_point', this.end_point);
              localStorage.setItem('start_point_A', JSON.stringify(this.start_point_A));
              localStorage.setItem('end_point_B', JSON.stringify(this.end_point_B));
              this.step = 3;
              this.$emit('setStep', 3);
              this.$emit('changedMap', false);
              this.$notify({
                group: 'main',
                type: 'success',
                text: res.data.message
              });
            }
          })
          .catch(error => {
            console.log(error);
            this.$notify({
              group: 'main',
              type: 'error',
              text: JSON.stringify(error)
            });
          });
    },

    cancelOrder() {
      let params = {
        id: this.order_id
      };
      this.isLoading = true;
      this.onCancel = true;
      setTimeout(() => this.onCancel = false, 2000);

      ApiService.cancelOrder(params)
          .then(res => {
            this.order_id = null;
            this.step = 1;
            this.$emit('setStep', 1);
            this.$emit('changedMap', true);
            localStorage.removeItem('order_id');
            localStorage.removeItem('partner');
            localStorage.removeItem('price');
            localStorage.removeItem('start_point');
            localStorage.removeItem('end_point');
            localStorage.removeItem('start_point_A');
            localStorage.removeItem('end_point_B');
            this.newOrder();
            this.isLoading = false;
            this.$notify({
              group: 'main',
              type: 'success',
              text: res.data.message
            });
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
            this.$notify({
              group: 'main',
              type: 'error',
              text: JSON.stringify(error)
            });
          });
    },

  },

}
</script>

<style>

</style>
