import Servers from './servers';

export default {
    serverInstance: Servers.AuylTaxiServer,
    async findByLocation(data) {
        return (await Servers.AuylTaxiServer.get('/town/find-by-location' + data));
    },
    async createOrder(data) {
        return (await Servers.AuylTaxiServer.post('/v2/order', data));
    },
    async checkOrder(data) {
        return (await Servers.AuylTaxiServer.get('/order/status' + data));
    },
    async cancelOrder(data) {
        return (await Servers.AuylTaxiServer.post('/order/cancel', data));
    },
}

