<template>
  <div class="step-3">
    <bottom-modal
        :close="close"
        style="background: #fefefe; padding-left: 5px; padding-right: 5px;"
        @setCurrentLocation="(e) => $emit('setCurrentLocation', e)"
    >
      <div class="step-3__header" style="padding-top: 5px;">
        <div class="step-3__header-start" style="display: grid; align-content: space-between; height: 100%;">
          <div class="step-3__taxi-name" style="font-size: 16px; font-weight: 500; padding-bottom: 5px;">Бағасы</div>
          <div class="step-3__taxi-name" style="color: #5d5d5f;"> {{ price }} тг</div>
        </div>
        <div class="step-3__header-start">
          <div style="display: grid; align-content: space-around; justify-items: flex-end; margin-right: 10px;">
            <div class="step-3__taxi-name">{{ partner.car_model }}</div>
            <div class="step-3__taxi-name" style="font-weight: 500;">{{ partner.car_number }}</div>
          </div>
          <div class="step-3__taxi-logo">
            <img src="images/car.png">
          </div>
        </div>
      </div>
      <div class="step-3__header" style="padding-top: 5px; padding-bottom: 5px;">
        <div class="step-3__header-start">
          <div class="step-3__taxi-logo">
            <img src="images/auyl-taxi.png">
          </div>
          <div>
            <div class="step-3__taxi-name" style="font-weight: 500;">{{ partner.first_name }} {{ partner.last_name }}</div>
            <div class="step-3__taxi-rating">
              <img src="images/star.png">
              <div class="step-3__taxi-rating-number" style="font-weight: 500;">{{ rating }}</div>
            </div>
          </div>
        </div>
        <div class="step-3__taxi-links">
          <div class="step-3__taxi-link">
            <div style="background: #bee84b; border-radius: 10px; padding: 7px; display: flex; align-items: center; justify-content: center;">
              <img src="images/sms.png" @click="onClickMessage">
            </div>
          </div>
          <div class="step-3__taxi-link">
            <div style="background: #bee84b; border-radius: 10px; padding: 7px; display: flex; align-items: center; justify-content: center;">
              <img src="images/phone.png" @click="onClickCall">
            </div>
          </div>
        </div>
      </div>
      <button
          class="step-3__cancel"
          style="font-weight: 500;"
          @click="$emit('newOrder')"
      >Жаңа тапсырыс беру</button>
    </bottom-modal>
  </div>
</template>

<script>
import BottomModal from "./BottomModal.vue";

export default {
  name: 'TestStep3',

  components: {
    BottomModal
  },

  props: [ 'partner', 'price', 'close', 'is_address_a', 'is_address_b' ],

  data() {
    return {
      rating: ''
    }
  },

  mounted() {
    this.rating = (4.4 + (this.randInt(1, 6) / 10));

    if (this.rating.toString().length > 3) {
      this.rating = this.rating.toString().slice(0, 3);
    }
  },

  methods: {

    onClickMessage() {
      window.open('https://api.whatsapp.com/send/?phone=' + this.partner.phone + '&text&type=phone_number&app_absent=0');
    },

    onClickCall() {
      window.open('tel:' + this.partner.phone);
    },

    randInt(min, max) {
      return Math.floor(min + Math.random()*(max - min + 1));
    },

  },

}
</script>

<style>
.step-3__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 5px 5px 5px;
}
.step-3__header-start {
  display: flex;
}
.step-3__taxi-logo {
  width: 50px;
  margin: 0 15px 0 0;
}
.step-3__taxi-logo img {
  width: 100%;
}
.step-3__taxi-rating {
  display: flex;
  margin: 5px 0 0 0;
}
.step-3__taxi-rating img {
;
}
.step-3__taxi-rating-number {
  margin: 0 0 0 5px;
}
.step-3__taxi-links {
  display: flex;
}
.step-3__taxi-link {
  width: 50px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-3__taxi-link img {
  width: 100%;
}

.step-3__lines {
  margin: 10px 0 0 0;
}
.point-AB-line {
  position: absolute;
  border: 1px dashed #c8c7cc;
  height: 40px;
  margin: 40px 0 0 11px;
  z-index: -10;
}

.point-A {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 5px);
  padding: 0 0 0 10px;
}
.point-A-icon {
  width: 25px;
  cursor: pointer;
}
.point-A-value {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  border-bottom: 1px solid #EFEFF4;
  padding: 8px 33px 8px 12px;
  margin: 0 0 0 10px;
  outline: none;
  color: #242E42;
  cursor: pointer;
  overflow-x: scroll;
}

.point-B {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 5px);
  padding: 0 0 0 10px;
  margin: 10px 0 0 0;
}
.point-B-icon {
  width: 25px;
  cursor: pointer;
}
.point-B-value {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  border-bottom: 0;
  padding: 8px 33px 8px 12px;
  margin: 0 0 0 10px;
  outline: none;
  color: #242E42;
  cursor: pointer;
  overflow-x: scroll;
}

.step-3__taxi-infos {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.step-3__taxi-info {
  margin: 0 15px 15px 15px;
}
.step-3__taxi-info-title {
  font-family: 'SF UI Display Medium';
  color: #C8C7CC;
  font-size: 13px;
  line-height: 15.51px;
  margin: 0 0 10px 0;
}
.step-3__taxi-info-value {
  font-family: 'SF UI Display Medium';
  color: #242E42;
  font-size: 15px;
  line-height: 17.9px;
}

.step-3__cancel {
  width: calc(100% - 10px);
  border: 0;
  border-radius: 10px;
  background: #f4f5f7;
  color: #040406;
  padding: 15px 0;
  margin: 15px 5px 10px 5px;
  z-index: 999999;
  cursor: pointer;
}
</style>
