<template>
  <div class="step-1">
    <bottom-modal
        :close="close"
        @setCurrentLocation="(e) => $emit('setCurrentLocation', e)"
    >
      <div class="content-service-inside">
        <div class="point-A">
          <div class="point-AB-line"></div>
          <img
              class="point-A-icon"
              src="/images/marker-a.png"
              @click="setA"
          >
          <input
              :class="'point-A-value' + (is === 'A' ? ' point-A-value-active' : '')"
              type="text"
              placeholder="Шығатын мекен-жай"
              :value="is_address_a"
              @input="e => $emit('newA', e)"
              @click="setA"
          >
          <div class="point-close" @click="$emit('clearPoint', 'A')">&#10006;</div>
        </div>
        <div class="point-B">
          <img
              class="point-B-icon"
              src="/images/marker-b.png"
              @click="setB"
          >
          <input
              :class="'point-B-value' + (is === 'B' ? ' point-B-value-active' : '')"
              type="text"
              placeholder="Баратын мекен-жай"
              :value="is_address_b"
              @input="e => $emit('newB', e)"
              @click="setB"
          >
          <div class="point-close" @click="$emit('clearPoint', 'B')">&#10006;</div>
        </div>
        <new-input-phone
            class="step-1-phone"
            label="Телефон нөмеріңіз"
            link_image="images/phone-call-1.png"
            @setValue="setPhone"
        />
        <new-input
            class="step-1-price"
            label="Ұсынатын бағаңыз"
            :value="price"
            type="number"
            link_image="images/tenge-1.png"
            @setValue="setPrice"
        />
        <div class="content-submit">
          <button
              class="content-submit-btn"
              @click="submit"
              :disabled="!city_has"
          >Жіберу</button>
        </div>
      </div>
    </bottom-modal>
  </div>
</template>

<script>
import BottomModal from "./BottomModal.vue";
import NewInput from "./NewInput.vue";
import NewInputPhone from "./NewInputPhone.vue";

export default {
  name: 'TestStep1',

  components: {
    NewInput,
    NewInputPhone,
    BottomModal
  },

  props: [ 'city_has', 'town', 'close', 'is_address_a', 'is_address_b', 'is_a', 'is_b' ],

  data() {
    return {
      is: null,
      phone_number: '',
      price: ''
    }
  },

  watch: {
    is_address_a: {
      handler() {
        if (this.is === 'A') {
          let inside = document.getElementsByClassName('point-A-value')[0];
          inside.style.border = '1px';
        }
      },
      deep: true
    },
    is_address_b: {
      handler() {
        if (this.is === 'B') {
          let inside = document.getElementsByClassName('point-B-value')[0];
          inside.style.border = '1px';
        }
      },
      deep: true
    }
  },

  methods: {

    setPhone(e) {
      this.phone_number = e;
      let inside = document.getElementsByClassName('step-1-phone')[0];
      inside.style.border = '1px solid #efefef';
    },

    setPrice(e) {
      this.price = e;
      let inside = document.getElementsByClassName('step-1-price')[0];
      inside.style.border = '1px solid #efefef';
    },

    submit() {

      let errored = false;

      if (!this.is_address_a) {
        let inside = document.getElementsByClassName('point-A-value')[0];
        inside.style.border = '1px solid #ff0000';
        errored = true;
      }

      if (!this.is_a) {
        let inside = document.getElementsByClassName('point-A-value')[0];
        inside.style.border = '1px solid #ff0000';
        errored = true;
        return this.$notify({
          group: 'main',
          type: 'error',
          text: 'Шығатын мекен-жайыңызды картадан белгілеңіз'
        });
      }

      if (!this.is_address_b) {
        let inside = document.getElementsByClassName('point-B-value')[0];
        inside.style.border = '1px solid #ff0000';
        errored = true;
      }

      if (!this.is_b) {
        let inside = document.getElementsByClassName('point-B-value')[0];
        inside.style.border = '1px solid #ff0000';
        errored = true;
        return this.$notify({
          group: 'main',
          type: 'error',
          text: 'Баратын мекен-жайыңызды картадан белгілеңіз'
        });
      }

      if (this.phone_number.length !== 16) {
        let inside = document.getElementsByClassName('step-1-phone')[0];
        inside.style.border = '1px solid #ff0000';
        errored = true;
      }

      if (!this.price) {
        let inside = document.getElementsByClassName('step-1-price')[0];
        inside.style.border = '1px solid #ff0000';
        errored = true;
      } else if (parseInt(this.price) < this.town.min_price) {
        let inside = document.getElementsByClassName('step-1-price')[0];
        inside.style.border = '1px solid #ff0000';
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Ең төмеңгі баға ' + this.town.min_price + ' тг'
        });
        return;
      } else if (parseInt(this.price) > this.town.max_price) {
        let inside = document.getElementsByClassName('step-1-price')[0];
        inside.style.border = '1px solid #ff0000';
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Ең жоғарғы баға ' + this.town.max_price + ' тг'
        });
        return;
      }

      if (errored) {
        this.$notify({
          group: 'main',
          type: 'error',
          text: 'Барлық мәліметтерді толтырыңыз'
        });
        return;
      }

      let data  = {
        points: [
          {
            title: this.is_address_a,
            lat: this.is_a._latlng.lat,
            lng: this.is_a._latlng.lng,
          },
          {
            title: this.is_address_b,
            lat: this.is_b._latlng.lat,
            lng: this.is_b._latlng.lng,
          }
        ],
        phone: '+7 ' + this.phone_number,
        price: parseInt(this.price),
        town_id: this.town.id
      };

      this.$emit('createOrder', data);

    },

    setA() {
      if (this.is === 'A') {
        this.is = null;
        this.$emit('setIs', null);
      }
      else {
        let inside = document.getElementsByClassName('point-A-value')[0];
        inside.style.border = '1px';
        this.is = 'A';
        this.$emit('setIs', 'A');
      }
    },

    setB() {
      if (this.is === 'B') {
        this.is = null;
        this.$emit('setIs', null);
      }
      else {
        let inside = document.getElementsByClassName('point-B-value')[0];
        inside.style.border = '1px';
        this.is = 'B';
        this.$emit('setIs', 'B');
      }
    },

  }

}
</script>

<style>

.content-service {
  position: relative;
}
.content-service-inside {
  display: block;
  justify-items: flex-start;
  position: relative;
}

.point-AB-line {
  position: absolute;
  border: 1px dashed #c8c7cc;
  height: 40px;
  margin: 40px 0 0 11px;
  z-index: -10;
}

.point-A {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 5px);
  padding: 0 0 0 10px;
}
.point-A-icon {
  width: 25px;
  cursor: pointer;
}
.point-A-value {
  border: 1px;
  border-radius: 0.375em;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  padding: 8px 33px 8px 12px;
  margin: 0 0 0 10px;
  outline: none;
  color: #242E42;
  cursor: pointer;
  overflow-x: scroll;
}
.point-A-value-active {
  font-weight: bold;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
  border-color: #86b7fe;
}

.point-B {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 5px);
  padding: 0 0 0 10px;
  margin: 10px 0 0 0;
}
.point-B-icon {
  width: 25px;
  cursor: pointer;
}
.point-B-value {
  border: 1px;
  border-radius: 0.375em;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  padding: 8px 33px 8px 12px;
  margin: 0 0 0 10px;
  outline: none;
  color: #242E42;
  cursor: pointer;
  overflow-x: scroll;
}
.point-B-value-active {
  font-weight: bold;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
  border-color: #86b7fe;
}
.point-close {
  background: #fff;
  position: absolute;
  top: calc(100% - 75%);
  right: 10px;
  padding-left: 10px;
  z-index: 999999;
}

.content-submit {
  width: 100%;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-submit-btn {
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
  font-size: 15px;
  width: calc(100% - 10px);
  background: #4CE5B1;
  color: #fff;
  cursor: pointer;
}
.content-submit-btn:hover {
  background: #3fb88e;
}
.leaflet-bottom.leaflet-right {
  display: none;
}

</style>
