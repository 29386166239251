<template>
  <div
      :class="class_bottom_modal"
  >
    <div class="bottom-modal-view-action">
      <div class="bottom-modal-view-action-btn"></div>
    </div>
    <div class="bottom-modal-content">
      <div class="bottom-modal-content-inside">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomModal',

  props: [ 'step', 'close' ],

  data() {
    return {
      id: '',
      mouse_is_down: false,
      top_mouse: 0,
      min_height: 50,
      scroll_top: 0,
      last_scroll_top: 0,
      is_scroll: false,
      allowed_for_scrolling: false,
      max_height: 0
    }
  },

  created() {
    this.id = this.rand_string(10);
  },

  mounted() {
    let bottom_modal = document.getElementsByClassName('bottom-modal-' + this.id)[0];
    bottom_modal.addEventListener('touchmove', this.bottom_modal_mouse_move);
    bottom_modal.addEventListener('touchstart', this.bottom_modal_mouse_down);
    bottom_modal.addEventListener('touchend', this.bottom_modal_mouse_up);
    bottom_modal.addEventListener('scroll', this.bottom_modal_mouse_scroll);
  },

  computed: {

    class_bottom_modal() {
      let add = '';

      if (this.close) {
        add = ' bottom-modal-close';
      }

      return 'bottom-modal bottom-modal-' + this.id + add;
    },

  },

  watch: {
    close: {
      handler() {
        let bottom_modal = document.getElementsByClassName('bottom-modal-' + this.id)[0];

        if (this.close) {
          bottom_modal.style.bottom = '0';
          bottom_modal.style.maxHeight = '30%';
          this.$emit('setCurrentLocation', { top: '100%', anim: true });
          bottom_modal.style.removeProperty('top');
        } else {
          bottom_modal.style.bottom = '0';
          this.$emit('setCurrentLocation', { top: '70%', anim: false });
          bottom_modal.style.maxHeight = '30%';
        }
      },
      deep: true
    }
  },

  methods: {

    bottom_modal_mouse_move(e) {
      if (this.mouse_is_down) {
        this.check_mouse_down(e.touches[0]);
      }
    },

    check_mouse_down(e) {
      let bottom_modal = document.getElementsByClassName('bottom-modal-' + this.id)[0];
      let body = document.body;
      let top = e.clientY - this.top_mouse;

      if (this.is_scroll) {

        top = ((top - this.last_scroll_top) * -1);

        bottom_modal.scrollTo(0, top);
        return false;
      }

      if (this.allowed_for_scrolling && top < 0) {
        this.is_scroll = true;
        bottom_modal.style.overflowY = 'auto';
        return;
      }

      top = top > this.max_height ? top : this.max_height;
      top = top < (body.offsetHeight - this.min_height) ? top : ((body.offsetHeight - this.min_height) + 'px');

      this.$emit('setCurrentLocation', { top: (top + 'px'), anim: false });

      bottom_modal.style.top = top + 'px';

      let height = ((body.offsetHeight + this.top_mouse) - e.clientY);
      height = top < (body.offsetHeight - this.min_height) ? height : '50px';
      bottom_modal.style.height = height + 'px';
    },

    bottom_modal_mouse_down(e) {
      if (!this.mouse_is_down) {
        let bottom_modal = document.getElementsByClassName('bottom-modal-' + this.id)[0];
        let bottom_modal_content = bottom_modal.getElementsByClassName('bottom-modal-content-inside')[0];
        let bottom_modal_view_action = bottom_modal.getElementsByClassName('bottom-modal-view-action')[0];
        let body = document.body;
        this.mouse_is_down = true;
        this.top_mouse = e.touches[0].clientY - bottom_modal.offsetTop;

        if (body.offsetHeight < (bottom_modal_content.offsetHeight + bottom_modal_view_action.offsetHeight)) {
          this.max_height = 0;
        } else {
          this.max_height = body.offsetHeight - (bottom_modal_content.offsetHeight + bottom_modal_view_action.offsetHeight);
        }

        bottom_modal.style.maxHeight = '100%';
        this.check_mouse_down(e.touches[0]);
      }
    },

    bottom_modal_mouse_up() {
      let bottom_modal = document.getElementsByClassName('bottom-modal-' + this.id)[0];
      let top = parseFloat(bottom_modal.style.top.split('px')[0]);
      this.mouse_is_down = false;

      if (this.is_scroll) {
        if (this.scroll_top <= 0) {
          this.is_scroll = false;
          bottom_modal.style.overflowY = 'hidden';
        } else {
          this.last_scroll_top = this.scroll_top;
        }
      }

      if (top <= 0) {
        this.allowed_for_scrolling = true;
      } else if (this.scroll_top <= 0) {
        this.allowed_for_scrolling = false;
        this.last_scroll_top = 0;
      }
    },

    bottom_modal_mouse_scroll(e) {
      this.scroll_top = e.target.scrollTop;
    },

    rand_string(length) {
      let result = '';
      let counter = 0;

      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;

      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }

      return result;
}

  }

}
</script>

<style>
.bottom-modal {
  background: #f6f6f6;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 30%;
  z-index: 99999;
  overflow-y: hidden;
  transition: bottom 1s;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  user-select: none;
}
.bottom-modal-close {
  bottom: -100% !important;
}
.bottom-modal-view-action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}
.bottom-modal-view-action-btn {
  background: #585858;
  width: 50px;
  height: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.bottom-modal-content {
  ;
}
</style>
