<template>
  <div class="form-group">
    <label class="form-group-icon">
      <img class="form-group-image" :src="link_image">
    </label>
    <input
        class="form-field"
        :type="type ? type : 'text'"
        :placeholder="label"
        :value="value"
        @change="(e) => $emit('setValue', e.target.value)"
    >
  </div>
</template>

<script>
export default {
  name: 'NewInput',

  props: {
    label: {},
    value: {},
    type: {},
    link_image: {}
  }

}
</script>

<style>
:root {
  --input-color: #99a3ba;
  --input-border: #cdd9ed;
  --input-background: #fff;
  --input-placeholder: #cbd1dc;
  --input-border-focus: #275efe;
  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #eef4ff;
  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #678efe;
}
.form-field {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--input-color);
  border: 0;
  background: var(--input-background);
  transition: border 0.3s ease;
}
.form-field::placeholder {
  color: var(--input-placeholder);
}
.form-field:focus {
  outline: none;
  border-color: var(--input-border-focus);
}
.form-group {
  border: 1px solid #efefef;
  position: relative;
  display: flex;
  min-width: calc(100% - 10px);
  margin: 5px;
}
.form-group-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  border-radius: 15px;
}
.form-group-image {
  max-width: 60%;
  max-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-group > span, .form-group .form-field {
  white-space: nowrap;
  display: block;
}
.form-group > span:not(:first-child):not(:last-child), .form-group .form-field:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.form-group > span:first-child, .form-group .form-field:first-child {
  border-radius: 6px 0 0 6px;
}
.form-group > span:last-child, .form-group .form-field:last-child {
  border-radius: 0 6px 6px 0;
}
.form-group > span:not(:first-child), .form-group .form-field:not(:first-child) {
  margin-left: -1px;
}
.form-group .form-field {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  width: 1%;
  margin-top: 0;
  margin-bottom: 0;
}
.form-group > span {
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 25px;
  color: var(--group-color);
  background: var(--group-background);
  border: 1px solid var(--group-border);
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}
.form-group:focus-within > span {
  color: var(--group-color-focus);
  background: var(--group-background-focus);
  border-color: var(--group-border-focus);
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
* {
  box-sizing: inherit;
}
*:before, *:after {
  box-sizing: inherit;
}
body {
  min-height: 100vh;
  font-family: 'Mukta Malar', Arial;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f5f9ff;
}
body .form-group {
  max-width: 360px;
}

</style>