import Vue from 'vue';
import VueRouter from 'vue-router';
import AuylTaxi from './components/AuylTaxi.vue';
import Navigator from './components/Navigator.vue';
import Privacy from "./components/Privacy.vue";

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: AuylTaxi
        },
        {
            path: '/navigator',
            component: Navigator
        },
        {
            path: '/privacy',
            component: Privacy
        },
    ]
});
