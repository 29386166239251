import axios from 'axios';

axios.defaults.headers.common['ngrok-skip-browser-warning'] = "any value";

const AuylTaxiServer = axios.create({
    baseURL: process.env.VUE_APP_API_HOST + '/api/',
    headers: {}
})

export default {
    AuylTaxiServer,
}

