<template>
  <div id="app">
    <notifications group="main" />

    <router-view></router-view>

  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style>
.vue-notification-group {
  z-index: 99999999999 !important;
  width: 100% !important;
  left: 0 !important;
  margin-top: 5px;
}
</style>
