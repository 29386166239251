<template>
  <div class="current-location" :style="'top: ' + ('calc(' + top.top + ' - 55px)')">
    <img src="/images/current_location.png" @click="currentLocation">
  </div>
</template>

<script>
export default {
  name: 'CurrentLocation',

  data() {
    return {
      last_top: false,
      coordinates: 'yeah'
    }
  },

  props: [ 'top' ],

  watch: {
    top: {
      handler() {
        let currentLocation = document.getElementsByClassName('current-location')[0];

        if (this.top.anim) {
          currentLocation.style.transition = 'top 0.5s';
        } else {
          setTimeout(() => currentLocation.style.transition = 'top 0s', 400);
        }

        this.last_top = this.top;
      },
      deep: true
    }
  },

  methods: {

    currentLocation() {
      this.$emit('setLoading', true);
      this.$getLocation({})
          .then(coordinates => {
            this.coordinates = coordinates;
            this.$emit('toCurrentLocation', coordinates);
            this.$emit('setLoading', false);
          })
    }

  }
}
</script>

<style>
.current-location {
  position: fixed;
  right: 20px;
  width: 40px;
  cursor: pointer;
  z-index: 99998;
  transition: top 1s;
}
</style>
