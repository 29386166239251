import AuylTaxiApi from './auyl-taxi-api'

export default {
    ...AuylTaxiApi,

    setAuthToken(token) {
        AuylTaxiApi.serverInstance.defaults.headers['authorization'] = token
    }
}

