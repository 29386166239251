<template>
  <div class="step-2">
    <bottom-modal
        :close="close"
        style="background: #fefefe;"
        @setCurrentLocation="(e) => $emit('setCurrentLocation', e)"
    >

      <div style="display: flex; align-items: center; justify-content: center; padding: 20px 0;">
        <img src="images/map-search.png" style="width: 80px;">
      </div>

      <div class="loading-taxi-text">
        Жүргізушіні іздеуде...
      </div>

      <div class="loading-taxi-text-2">
        Бұл кішкене уақыт алады
      </div>

      <div class="text">
        <div class="point-A">
          <div class="point-AB-line"></div>
          <img
              class="point-A-icon"
              src="/images/marker-a-2.png"
          >
          <input
              class="point-A-value"
              type="text"
              placeholder="Шығатын мекен-жай"
              :value="start_point"
              disabled
          >
        </div>
        <div class="point-B">
          <img
              class="point-B-icon"
              src="/images/marker-b-2.png"
          >
          <input
              class="point-B-value"
              type="text"
              placeholder="Баратын мекен-жайй"
              :value="end_point"
              disabled
          >
        </div>
      </div>

      <div>
        <button
            class="step-2__cancel"
            @click="$emit('cancelOrder')"
        >Тапсырысты кері қайтару</button>
      </div>

    </bottom-modal>
  </div>
</template>

<script>
import BottomModal from "./BottomModal.vue";

export default {
  name: 'TestStep2',

  components: {
    BottomModal
  },

  props: [ 'close', 'start_point', 'end_point' ]

}
</script>

<style>

.loading-taxi-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #040406;
  font-family: 'SF UI Display Medium';
  font-size: 20px;
  margin-top: 5px;
}

.loading-taxi-text-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7f7e81;
  font-family: 'SF UI Display Medium';
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.step-2__cancel {
  width: calc(100% - 10px);
  border: 0;
  border-radius: 10px;
  background: #f4f5f7;
  color: #040406;
  padding: 15px 0;
  margin: 15px 5px 0 5px;
  z-index: 999999;
  cursor: pointer;
}
</style>
