<template>
  <div class="form-group">
    <label class="form-group-icon">
      <img class="form-group-image" :src="link_image">
    </label>
    <input
        value="+7"
        style="width: 40px !important; padding: 8px 0 8px 16px;"
        class="form-field"
        @keydown="on7"
    >
    <input
        id="phoneNumber"
        class="form-field"
        style="width: 100% !important; padding: 8px 16px 8px 0;"
        :type="type ? type : 'text'"
        :placeholder="label"
        maxlength="16"
        @keydown="enforceFormat"
        @keyup="formatToPhone"
    >
  </div>
</template>

<script>
export default {
  name: 'NewInput',

  props: {
    label: {},
    type: {},
    link_image: {}
  },

  methods: {

    on7(e) {
      e.preventDefault()
    },

    isNumericInput(event) {
      const key = event.keyCode;
      return ((key >= 48 && key <= 57) || // Allow number line
          (key >= 96 && key <= 105) // Allow number pad
      );
    },

    isModifierKey(event) {
      const key = event.keyCode;
      return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
          (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
          (key > 36 && key < 41) || // Allow left, up, right, down
          (
              // Allow Ctrl/Command + A,C,V,X,Z
              (event.ctrlKey === true || event.metaKey === true) &&
              (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
          )
    },

    enforceFormat(event) {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if(!this.isNumericInput(event) && !this.isModifierKey(event)){
        event.preventDefault();
      }
    },

    formatToPhone(event) {
      if (this.isModifierKey(event)) {
        let inside = document.getElementById('phoneNumber');
        this.$emit('setValue', inside.value);
        return;
      }

      const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
      const areaCode = input.substring(0,3);
      const middle = input.substring(3,6);
      const last = input.substring(6,10);

      if(input.length > 6){event.target.value = `(${areaCode}) ${middle} - ${last}`;}
      else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
      else if(input.length > 0){event.target.value = `(${areaCode}`;}

      let inside = document.getElementById('phoneNumber');
      this.$emit('setValue', inside.value);
    },

  }

}
</script>

<style>
:root {
  --input-color: #99a3ba;
  --input-border: #cdd9ed;
  --input-background: #fff;
  --input-placeholder: #cbd1dc;
  --input-border-focus: #275efe;
  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #eef4ff;
  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #678efe;
}
.form-field {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--input-color);
  border: 0;
  background: var(--input-background);
  transition: border 0.3s ease;
}
.form-field::placeholder {
  color: var(--input-placeholder);
}
.form-field:focus {
  outline: none;
  border-color: var(--input-border-focus);
}
.form-group {
  border: 1px solid #efefef;
  position: relative;
  display: flex;
  min-width: calc(100% - 10px);
  margin: 5px;
}
.form-group-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  border-radius: 15px;
}
.form-group-image {
  max-width: 60%;
  max-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-group > span, .form-group .form-field {
  white-space: nowrap;
  display: block;
}
.form-group > span:not(:first-child):not(:last-child), .form-group .form-field:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.form-group > span:first-child, .form-group .form-field:first-child {
  border-radius: 6px 0 0 6px;
}
.form-group > span:last-child, .form-group .form-field:last-child {
  border-radius: 0 6px 6px 0;
}
.form-group > span:not(:first-child), .form-group .form-field:not(:first-child) {
  margin-left: -1px;
}
.form-group .form-field {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  width: 1%;
  margin-top: 0;
  margin-bottom: 0;
}
.form-group > span {
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 25px;
  color: var(--group-color);
  background: var(--group-background);
  border: 1px solid var(--group-border);
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}
.form-group:focus-within > span {
  color: var(--group-color-focus);
  background: var(--group-background-focus);
  border-color: var(--group-border-focus);
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
* {
  box-sizing: inherit;
}
*:before, *:after {
  box-sizing: inherit;
}
body {
  min-height: 100vh;
  font-family: 'Mukta Malar', Arial;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #f5f9ff;
}
body .form-group {
  max-width: 360px;
}

</style>