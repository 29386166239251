<template>
  <div class="auyl-taxi">
    <vue-element-loading
        :active="is_loading"
        style="z-index: 999999999;pointer-events: none;"
        :is-full-screen="true"
        spinner="spinner"
        color="#4CE5B1"
        @click="() => false"
        @mousedown="() => false"
        @mouseup="() => false"
        @mousemove="() => false"
    />
    <img
        v-if="step === 1"
        src="images/default-marker.png"
        style="width:40px;height:40px;position:fixed;top:calc(50% - 40px);left:calc(50% - 20px);z-index:99998;pointer-events: none;"
        @click="() => false"
        @mousedown="() => false"
        @mouseup="() => false"
        @mousemove="() => false"
    >
    <div id="map"></div>
    <current-location
        :top="current_location_top"
        @toCurrentLocation="toCurrentLocation"
        @setLoading="(e) => is_loading = e"
    />
    <steps
        :close="close"
        :is_address_a="is_address_a"
        :is_address_b="is_address_b"
        :is_a="is_a"
        :is_b="is_b"
        :town="town"
        :city_has="city_has"
        @newA="(e) => is_address_a = e.target.value"
        @newB="(e) => is_address_b = e.target.value"
        @setIs="(e) => is = e"
        @changedMap="(e) => changedMap = e"
        @setCurrentLocation="(e) => current_location_top = e"
        @clearPoint="clearPoint"
        @newOrder="newOrder"
        @setStep="(e) => step = e"
    />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet-routing-machine';
import Steps from './Steps.vue';
import CurrentLocation from './CurrentLocation.vue';
import axios from 'axios';
import VueElementLoading from "vue-element-loading";
import ApiService from "@/services/api";

export default {
  name: 'AuylTaxi',

  components: {
    VueElementLoading,
    Steps,
    CurrentLocation
  },

  data() {
    return {
      is_loading: true,
      is: 'A',
      close: false,
      is_address_a: '',
      is_address_b: '',
      start_point_A: '',
      end_point_B: '',
      current_location_top: { top: '60%', anim: false },
      coordinates: '',
      is_a: '',
      is_b: '',
      control: '',
      getted_location: false,
      city_has: false,
      town: '',
      changedMap: true,
      setNewIsABTime: '',
      step: 1
    }
  },

  async mounted() {
    await this.getLocation();
  },

  methods: {

    getLocation() {
      this.$getLocation({})
          .then(coordinates => {
            if (this.city_has) {
              this.is_loading = false;
            }
            if (!this.getted_location) {
              this.findByLocation(coordinates.lat, coordinates.lng);
            }
          })
          .catch(err => {
            console.log(err);
            this.$notify({
              group: 'main',
              type: 'error',
              text: 'Геолокацияны қосыңыз'
            });
            this.is_loading = true;
            setTimeout(this.getLocation, 1000);
          })
    },

    findByLocation(lat, lng) {
      this.is_loading = true;
      this.getted_location = true;
      let params = `?lat=${lat}&long=${lng}`;

      ApiService.findByLocation(params)
          .then(res => {
            if (res.data.data) {
              this.is_loading = false;
              this.city_has = true;
              this.town = res.data.data;
              this.setup();
            } else {
              this.is_loading = false;
              this.city_has = false;
              this.$notify({
                group: 'main',
                type: 'error',
                text: 'Біз әзірге сіздің қалаңызда жұмыс жасамаймыз'
              });
            }
          });
    },

    async setup() {

      let cord;
      await this.$getLocation({})
          .then(coordinates => {
            cord = coordinates;
          });
      var map = L.map('map').setView([ cord.lat, cord.lng ], 20);

      this.iconA = L.icon({
        iconUrl: 'images/marker-a.png',
        iconSize: [40, 40],
        iconAnchor: [20, 20]
      });

      this.iconB = L.icon({
        iconUrl: 'images/marker-b.png',
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      });

      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      this.map = map;

      if (localStorage.getItem('partner')) {
        this.start_point_A = JSON.parse(localStorage.getItem('start_point_A'));
        this.end_point_B = JSON.parse(localStorage.getItem('end_point_B'));
        this.setNewAB(this.start_point_A, this.end_point_B);
      }

      map.on('move', this.mapMouseDown);

      map.on('moveend', this.mapMouseUp);

      map.on('click', (e) => this.mapClick(e, map));

      this.is_loading = true;
      this.$getLocation({})
          .then(async (coordinates) => {
            await this.toCurrentLocation(coordinates);
            this.is_loading = false;
          })
    },

    newOrder() {
      localStorage.removeItem('order_id');
      localStorage.removeItem('partner');
      localStorage.removeItem('start_point');
      localStorage.removeItem('end_point');
      localStorage.removeItem('start_point_A');
      localStorage.removeItem('end_point_B');
      this.is = null;
      this.close = false;
      this.is_address_a = '';
      this.is_address_b = '';
      this.current_location_top = { top: '60%', anim: false };
      this.coordinates = '';
      if (this.is_a) this.is_a.remove();
      if (this.is_b) this.is_b.remove();
      if (this.control) this.control.remove();
      this.is_a = '';
      this.is_b = '';
      this.control = '';

      this.is_loading = true;
      this.$getLocation({})
          .then(async (coordinates) => {
            await this.toCurrentLocation(coordinates);
            this.is_loading = false;
          })
    },

    async toCurrentLocation(e) {

      if (!this.changedMap) {
        this.is_loading = false;
        return;
      }

      this.is_loading = true;
      await this.map.setView([ e.lat, e.lng ], 20);

      if (this.is_a) this.is_a.remove();
      this.is_a = await L.marker([ e.lat, e.lng ], {icon: this.iconA}).addTo(this.map);

      let is_address_a = await axios.get('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + this.is_a._latlng.lat + '&lon=' + this.is_a._latlng.lng);
      is_address_a = is_address_a.data;

      let name = is_address_a.name;
      name = name ? name : '';

      let road = is_address_a.address.road ? is_address_a.address.road : is_address_a.address.suburb;
      road = road ? (((name && name !== road) ? ', ' : '') + road) : '';

      let house_number = is_address_a.address.house_number;
      house_number = house_number ? (((name || road) ? ', ' : '') + house_number) : '';

      if (name === road) name = '';

      is_address_a = name + road + house_number;

      this.is_address_a = is_address_a;

      await this.checkRouting();
      this.is_loading = false;
    },

    mapMouseDown() {
      this.close = true;
    },

    async setNewIsAB() {
      if (!this.changedMap) return;

      if (!this.is) return;

      this.is_loading = true;
      let { lat, lng } = await this.map.getCenter();

      if (this.is === 'A') {
        if (this.is_a) this.is_a.remove();
        this.is_a = L.marker([lat, lng], {icon: this.iconA}).addTo(this.map);

        let is_address_a = await axios.get('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + this.is_a._latlng.lat + '&lon=' + this.is_a._latlng.lng);
        is_address_a = is_address_a.data;

        let name = is_address_a.name;
        name = name ? name : '';

        let road = is_address_a.address.road ? is_address_a.address.road : is_address_a.address.suburb;
        road = road ? (((name && name !== road) ? ', ' : '') + road) : '';

        let house_number = is_address_a.address.house_number;
        house_number = house_number ? (((name || road) ? ', ' : '') + house_number) : '';

        if (name === road) name = '';

        is_address_a = name + road + house_number;

        this.is_address_a = is_address_a;

      }
      if (this.is === 'B') {
        if (this.is_b) this.is_b.remove();
        this.is_b = L.marker([lat, lng], {icon: this.iconB}).addTo(this.map);

        let is_address_b = await axios.get('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + this.is_b._latlng.lat + '&lon=' + this.is_b._latlng.lng);
        is_address_b = is_address_b.data;

        let name = is_address_b.name;
        name = name ? name : '';

        let road = is_address_b.address.road ? is_address_b.address.road : is_address_b.address.suburb;
        road = road ? (((name && name !== road) ? ', ' : '') + road) : '';

        let house_number = is_address_b.address.house_number;
        house_number = house_number ? (((name || road) ? ', ' : '') + house_number) : '';

        if (name === road) name = '';

        is_address_b = name + road + house_number;

        this.is_address_b = is_address_b;
      }

      await this.checkRouting();

      this.is_loading = false;
    },

    mapMouseUp() {
      this.close = false;

      if (this.setNewIsABTime) clearTimeout(this.setNewIsABTime);
      this.setNewIsABTime = setTimeout(this.setNewIsAB, 1000);
    },

    async mapClick(e) {

      if (!this.changedMap) return;

      if (!this.is) return;

      this.is_loading = true;

      if (this.is === 'A') {
        if (this.is_a) this.is_a.remove();
        this.is_a = L.marker([e.latlng.lat, e.latlng.lng], {icon: this.iconA}).addTo(this.map);

        let is_address_a = await axios.get('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + this.is_a._latlng.lat + '&lon=' + this.is_a._latlng.lng);
        is_address_a = is_address_a.data;

        let name = is_address_a.name;
        name = name ? name : '';

        let road = is_address_a.address.road ? is_address_a.address.road : is_address_a.address.suburb;
        road = road ? (((name && name !== road) ? ', ' : '') + road) : '';

        let house_number = is_address_a.address.house_number;
        house_number = house_number ? (((name || road) ? ', ' : '') + house_number) : '';

        if (name === road) name = '';

        is_address_a = name + road + house_number;

        this.is_address_a = is_address_a;

      }
      if (this.is === 'B') {
        if (this.is_b) this.is_b.remove();
        this.is_b = L.marker([e.latlng.lat, e.latlng.lng], {icon: this.iconB}).addTo(this.map);

        let is_address_b = await axios.get('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + this.is_b._latlng.lat + '&lon=' + this.is_b._latlng.lng);
        is_address_b = is_address_b.data;

        let name = is_address_b.name;
        name = name ? name : '';

        let road = is_address_b.address.road ? is_address_b.address.road : is_address_b.address.suburb;
        road = road ? (((name && name !== road) ? ', ' : '') + road) : '';

        let house_number = is_address_b.address.house_number;
        house_number = house_number ? (((name || road) ? ', ' : '') + house_number) : '';

        if (name === road) name = '';

        is_address_b = name + road + house_number;

        this.is_address_b = is_address_b;
      }

      await this.checkRouting();

      this.is_loading = false;
    },

    clearPoint(is) {
      if (is === 'A') {
        this.is = 'A';
        if (this.is_address_a) this.is_address_a = '';
        if (this.control) {
          this.control.remove();
          this.control = '';
        }
        if (this.is_a) {
          this.is_a.remove();
          this.is_a = '';
        }
      } else if (is === 'B') {
        this.is = 'B';
        if (this.is_address_b) this.is_address_b = '';
        if (this.control) {
          this.control.remove();
          this.control = '';
        }
        if (this.is_b) {
          this.is_b.remove();
          this.is_b = '';
        }
      }
    },

    async setNewAB(a, b) {
      if (this.is_a && this.is_b) {
        return;
      }

      this.is_a = await L.marker([a.lat, a.lng], {icon: this.iconA}).addTo(this.map);
      this.is_b = await L.marker([b.lat, b.lng], {icon: this.iconB}).addTo(this.map);

      this.checkRouting();
    },

    checkRouting() {

      if (this.is_a && this.is_b) {
        if (this.control) {
          this.control.remove();
          this.control = '';
        }

        this.control = L.Routing.control({
          waypoints: [
            [this.is_a._latlng.lat, this.is_a._latlng.lng],
            [this.is_b._latlng.lat, this.is_b._latlng.lng]
          ],
          lineOptions: {
            styles: [{color: '#7439fb', opacity: 1, weight: 5}]
          },
          show: false,
          waypointMode: 'snap',
          createMarker: function() {}
        });

        this.control.addTo(this.map);
      }
    },

  },

}
</script>

<style>
@import '/css/font/sf-ui-display/style.css';
@import '/css/leaflet.css';

body {
  padding: 0;
  margin: 0;
  height: 100vh;
}
#app {
  width: 100%;
  height: 100%;
}
.auyl-taxi {
  width: 100%;
  height: 100%;
}
#map {
  width: 100%;
  height: 100%;
}
.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

</style>
